<link rel="stylesheet" href="//cdn.quilljs.com/1.3.6/quill.bubble.css" />
<link rel="stylesheet" href="//cdn.quilljs.com/1.3.6/quill.snow.css" />
<div class="back-office-panel">
    <div class="page-wrapper" [ngClass]="{ toggled: getSideBarState() }">
        <app-admin-sidebar class="no-print-me"></app-admin-sidebar>
        <main class="page-content">
            <app-cg-alerts></app-cg-alerts>
            <nav class="navbar navbar-dark stick-top no-print-me">
                <button type="button" class="sidebar-toggler" (click)="toggleSidebar()">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div class="dropdown-container">
                    <div class="dropdown">
                        <h4 class="drop-text"><i class="fas fa-user"></i> ADMIN <i class="fa fa-angle-down"></i></h4>
                        <div class="dropdown-content">
                            <a [routerLink]="['/admin/change-password']"><i class="fas fa-key"></i>Change Password</a>
                            <hr style="margin: 0" />
                            <a href="javascript: void(0);" (click)="logOut()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
            <div class="overlay" (click)="toggleSidebar()" [ngClass]="{ show: !getSideBarState() }"></div>
        </main>
    </div>
</div>