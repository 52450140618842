import { NotAuthorizedComponent } from './admin-shared/not-authorized/not-authorized.component'
import { PermissionsGuard } from '../auth/permissions-guard'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdminGuard } from '../auth/admin-guard'
import { AdminPanelComponent } from './admin-panel.component'

const routes: Routes = [
    {
        path: '',
        component: AdminPanelComponent,
        canActivate: [AdminGuard],
        children: [
            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'samples',
                data: { permissions: [''] },
                loadChildren: () =>
                    import('../general-modules/documentation/documentation.module').then(
                        (mod) => mod.DocumentationModule
                    )
            }
        ]
    },
    {
        path: '',
        component: AdminPanelComponent,
        canActivate: [AdminGuard],
        children: [
            {
                path: 'dashboard',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule)
            },
            {
                path: 'mentors',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () => import('./mentor/mentor.module').then((mod) => mod.MentorModule)
            },
            {
                path: 'parallels',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./parallels/parallels.module').then((mod) => mod.ParallelsModule)
            },
            {
                path: 'mentor-payments',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./payments/mentor-payments/mentor-payments.module').then(
                        (mod) => mod.MentorPaymentsModule
                    )
            },
            {
                path: 'parallel-payments',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./payments/parallel-payments/parallel-payments.module').then(
                        (mod) => mod.ParallelPaymentsModule
                    )
            },
            {
                path: 'parallel-inspections',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./Reports/parallel-inspections/parallel-inspections.module').then(
                        (mod) => mod.ParallelInspectionsModule
                    )
            },
            {
                path: 'mentor-inspections',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./Reports/mentor-inspections/mentor-inspections.module').then(
                        (mod) => mod.MentorInspectionsModule
                    )
            },
            {
                path: 'calendar',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./calendar/calendar.module').then((mod) => mod.CalendarModule)
            },

            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'price-setting',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./price-settings/price-settings.module').then(
                        (mod) => mod.PriceSettingsModule
                    )
            },
            {
                path: 'term-and-conditions',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./term-and-conditions/term-and-conditions.module').then(
                        (mod) => mod.TermAndConditionsModule
                    )
            },
            {
                path: 'change-password',
                canActivate: [AdminGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./change-password/change-password.module').then(
                        (mod) => mod.ChangePasswordModule
                    )
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminPanelRoutes {}
