import { AdminSharedModule } from './admin-shared/admin-shared.module'
import { SharedModule } from '../website/shared/shared.module'
import { NgModule } from '@angular/core'
import { AdminPanelRoutes } from './admin-panel.routing'
import { AdminPanelComponent } from './admin-panel.component';

@NgModule({
    imports: [
        AdminSharedModule,
        SharedModule,
        AdminPanelRoutes
    ],
    declarations: [AdminPanelComponent],
})
export class AdminPanelModule { }
